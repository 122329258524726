<template>
  <div class="card card-bordered">
      <div class="card-inner">
          <a-breadcrumb style="padding-bottom: 10px;">
            <router-link :to="{name: 'reports'}"><a-breadcrumb-item>Back To Reports</a-breadcrumb-item></router-link>
            <a-breadcrumb-item>Account Issues</a-breadcrumb-item>
          </a-breadcrumb>
          <div class="row">
                <div class="col-5">
                    <a-select show-search allowClear option-filter-prop="children" @change="accountSelected(widgetFetcher.accountId)" v-model="widgetFetcher.accountId" placeholder="Please Select An Account" style="width: 50%; margin-right: 10px;">
                        <a-select-option v-for="a in accounts" :key="a.id">
                            {{ a.text}}
                        </a-select-option>
                    </a-select>
                    <a-select show-search allowClear option-filter-prop="children" placeholder="Please Select An Issue Type" style="width: 45%">
                        <a-select-option v-for="a in allowances" :key="a.tenantTicketIssueTypeId" @click="issueTypeSelected(a)">
                            {{ a.issueType}}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="col-7">
                    <div class="float-right">
                        <div class="btn-group" style="margin-right: 15px;">
                            <button @click="setMonth('thisMonth')" type="button" class="btn btn-primary btn-sm">This Month</button>
                            <button @click="setMonth('lastMonth')" class="btn btn-dark btn-sm">Last Month</button>
                            <button @click="setMonth('monthBeforeLast')" class="btn btn-dark btn-sm">Month <em style="font-size:10px;" class="icon ni ni-caret-left-fill"></em> Last</button>
                            <button @click="setMonth('thisYear')" class="btn btn-dark btn-sm">This Year</button>
                        </div>
                        <a-date-picker @change="getReport()" v-model="widgetFetcher.start" format="DD/MM/YYYY" placeholder="Start Date" style="margin-right: 10px;"/>
                        <a-date-picker @change="getReport()" v-model="widgetFetcher.end" format="DD/MM/YYYY" placeholder="End Date"/>
                    </div>
                </div>
            </div>
            <p v-if="selectedIssueType" style="padding-top: 10px;">This account's initial start date for this allocation is {{$moment(selectedIssueType.createdDate).format('DD/MM')}}.</p>
            <hr class="preview-hr">
            <div class="col-12" v-if="issues">
                <vue-good-table v-if="issues"
                    :columns="columns"
                    :rows="issues"
                    styleClass="vgt-table condensed tblhov"
                    :search-options="{ enabled: true, placeholder: 'Search Issues'}"
                    :sort-options="{ enabled: true,}" 
                >
                <!-- <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'createdDate'">{{ $moment(props.row.createdDate).format("H:mm DD/MM/YY") }}</span>
                </template> -->
                </vue-good-table>
            </div>
            <hr class="preview-hr">
            <div class="col-12" v-if="tickets">
                <vue-good-table v-if="tickets"
                    :columns="ticketColumns"
                    :rows="tickets"
                    styleClass="vgt-table condensed tblhov"
                    :search-options="{ enabled: true, placeholder: 'Search Issues'}"
                    :sort-options="{ enabled: true,}"
                    @on-cell-click="showTicket"
                    :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 25,
                        position: 'bottom',
                        perPageDropdown: [25,50,100],
                        dropdownAllowAll: false,
                        setCurrentPage: 1,
                        nextLabel: 'Next',
                        prevLabel: 'Prev',
                        rowsPerPageLabel: 'Rows per page',
                        ofLabel: 'of',
                        pageLabel: 'page', // for 'pages' mode
                        allLabel: 'All',
                    }"
                >
                <template slot="table-row" slot-scope="props">
                    <span :class="props.row.slaDueDate > props.row.completedDate ? 'red' : '' " v-if="props.column.field == 'id'">{{ props.row.id }}</span>
                    <span :class="props.row.slaDueDate > props.row.completedDate ? 'red' : '' " v-else-if="props.column.field == 'title'">{{ props.row.title }}</span>
                    <span :class="props.row.slaDueDate > props.row.completedDate ? 'red' : '' " v-else-if="props.column.field == 'priority'">{{ props.row.priority }}</span>
                    <span :class="props.row.slaDueDate > props.row.completedDate ? 'red' : '' " v-else-if="props.column.field == 'status'">{{ props.row.status }}</span>
                    <span :class="props.row.slaDueDate > props.row.completedDate ? 'red' : '' " v-else-if="props.column.field == 'issueType'">{{ props.row.issueType }}</span>
                    <span :class="props.row.slaDueDate > props.row.completedDate ? 'red' : '' " v-else-if="props.column.field == 'createdDate'">{{ $moment(props.row.createdDate).format("H:mm DD/MM/YY") }}</span>
                    <span :class="props.row.slaDueDate > props.row.completedDate ? 'red' : '' " v-else-if="props.column.field == 'completedDate'">{{ $moment(props.row.completedDate).format("H:mm DD/MM/YY") }}</span>
                    <span :class="props.row.slaDueDate > props.row.completedDate ? 'red' : '' " v-else-if="props.column.field == 'slaDueDate' && props.row.slaDueDate">{{ $moment(props.row.slaDueDate).format("H:mm DD/MM/YY") }}</span>
                </template>
                </vue-good-table>
            </div>
      </div>
    <a-drawer :width="600"
      placement="right"
      :closable="false"
      @close="closeTicket()"
      :visible="showTicketData">
      <ticket :ticket="selectedTicket" @ticketSaved="getReport()" />
    </a-drawer>
  </div>
</template>

<script>
import ticket from '@/components/desk/tickets/ticket.vue'
export default {
    components: { ticket },
    data() {
        return {
            needsRefresh: null,
            accounts: [],
            widgetFetcher: { 
                start: this.$moment().startOf('month'),
                end: this.$moment().endOf('month'),
            },
            selectedMonth: 'thisMonth',
            issues: [],
            isueCounts: null,
            allowances: [],
            tickets: null,
            showTicketData: false,
            selectedTicket: {},
            selectedIssueType: null,
            columns: [{
              label: 'Account',
              align: 'start',
              sortable: false,
              field: 'accountName',
            }, {
              label: 'Issue Type',
              field: 'issueType'
            },  {
              label: 'Allowance',
              field: 'allowance'
            }, {
              label: 'Used',
              field: 'usage'
            }, {
              label: 'remaining',
              field: 'remaining'
            }],
            ticketColumns: [{
                label: 'Ticket No',
                align: 'start',
                sortable: true,
                field: 'id',
                type: 'number'
            },{
              label: 'Title',
              sortable: false,
              field: 'title',
            }, {
              label: 'Priority',
              field: 'priority'
            }, {
              label: 'Status',
              field: 'status'
            }, {
              label: 'Issue Type',
              field: 'issueType'
            }, {
              label: 'Created Date',
              field: 'createdDate'
            }, {
                label: 'Completed Date',
                field: 'completedDate'
            }, {
              label: 'SLA Due Date',
              field: 'slaDueDate'
            },
            ],
        }
    },
    created() {
      this.getView()
      this.getLists()
      // this.getReport()
    },
    methods: {
        getView (){ 
          this.$http.get('/ViewAccess/accountIssues')
          .then(() => { 
          })
          .catch(() => { 
        })},
        showTicket(params) {
          this.selectedTicket = params.row
          this.showTicketData = true
        },
        getLists(){
            this.$http.get('/lists/Get_List/accounts')
            .then((response) => {
                this.accounts = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        accountSelected(search) {
          this.$http.get('/accounts/Get_ActiveAccountIssueTypeAllowances/' + search)
          .then((response) => {
              this.allowances = response.data
          })
          .catch(() => {
              this.$message.error('There has been an error')
          })
        },
        issueTypeSelected(a) {
          this.selectedIssueType = a
          this.widgetFetcher.start = a.createdDate
          this.getReport()
        },
        getReport(){
          this.showTicketData = false
          this.widgetFetcher.issueTypeId = this.selectedIssueType.tenantTicketIssueTypeId
          this.$http.post('/reports/Get_AccountIssueUsage/', this.widgetFetcher)
          .then((response) => {
              this.issues = response.data
          })
          .catch(() => {
            this.$message.error('There has been an error')
          })
          this.$http.post('/reports/Get_TicketsByAccount/', this.widgetFetcher)
          .then((response) => {
              this.tickets = response.data
          })
          .catch(() => {
            this.$message.error('There has been an error')
          })
          //this.$http.post('/reports/Get_TicketsByUserCounts/', this.widgetFetcher)
          //.then((response) => {
          //    this.ticketCounts = response.data
          //})
          //.catch(() => {
          //  this.$message.error('There has been an error')
          //})
          this.initiateRefresh()
        },
        closeTicket(){
          this.selectedTicket = null
          this.showTicketData = false
        },
        setMonth(s){
            if (s === 'thisMonth') {
                this.widgetFetcher.start = this.$moment.utc().startOf('month'),
                this.widgetFetcher.end = this.$moment.utc().endOf('month')
            } else if (s === 'lastMonth') {
                this.widgetFetcher.start = this.$moment.utc().subtract(1, 'months').startOf('month'),
                this.widgetFetcher.end = this.$moment.utc().subtract(1, 'months').endOf('month')
            } else if (s === 'monthBeforeLast') {
                this.widgetFetcher.start = this.$moment.utc().subtract(2, 'months').startOf('month'),
                this.widgetFetcher.end = this.$moment.utc().subtract(2, 'months').endOf('month')
            } else if (s === 'thisYear') {
                this.widgetFetcher.start = this.$moment.utc().startOf('year')
            }
            this.getReport()
        },
        initiateRefresh(){
            if (this.needsRefresh === true) {
                this.needsRefresh = false
            } else {
                this.needsRefresh = true
            }
        }
    }
}
</script>

<style scoped>
  .stat{
    height: 165px
  }
  .red{
      color: red;
  }
</style>